import { ImageObject } from '@utils/types';
import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const Headline = ({
	children,
	image,
	as: Component = 'section',
	className = '',
}: {
	children: React.ReactNode;
	image: ImageObject;
	as?: React.ElementType;
	className?: string;
}) => {
	const gatsbyImage = getImage(image.src);

	return (
		<Component
			className={classNames(
				'section__key-headline section--padding background--white',
				{
					[className]: !!className,
				}
			)}>
			<div className="row">
				<div className="key-headline">
					{gatsbyImage && (
						<div className="key-headline-image">
							<GatsbyImage
								image={gatsbyImage}
								alt={image.alt}
								objectFit="cover"
							/>
						</div>
					)}
					<div className="key-headline-content">{children}</div>
				</div>
			</div>
			<div className="key-headline-shape">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					style={{ width: '992.032px', height: '1071.846px' }}>
					<path
						fill="#eee"
						d="M621.50701752 107.67326518l309.20207752 320.10496659L807.34842335 857.98802 375.50040181 965.60029007 61.32283375 644.06862452 184.68350544 213.8588363z"
						opacity=".7"></path>
				</svg>
			</div>
		</Component>
	);
};

export default Headline;
