// i18next-extract-mark-ns-start products-family-fd

import { LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Banner from '@components/Banner';
import ContentGrid from '@components/ContentGrid';
import Headline from '@components/Headline';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Breadcrumb } from '@components/Breadcrumbs';

const ProductFamilyFluidPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const iconWave = getImage(data.iconWave.childImageSharp.gatsbyImageData);
	const iconVectis = getImage(data.iconVectis.childImageSharp.gatsbyImageData
	);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Headline
				image={{
					src: data.icon.childImageSharp.gatsbyImageData,
					alt: '',
				}}>
				<Trans i18nKey="headline">
					<p>
						A comprehensive range of tools for 1D and 3D CFD simulation of liquid and gas flow.
						Enabling prediction, optimisation and calibration as well as detailed combustion and
						thermal analysis to model Internal Combustion Engine (ICE) behaviour across a range of fuel types.
					</p>
				</Trans>
			</Headline>

			<ContentGrid>
				<ContentGrid.Item
					image={{
						src: data.gridA.childImageSharp.gatsbyImageData,
						alt: '',
					}}>
					{iconWave && (
						<GatsbyImage
							image={iconWave}
							alt="WAVE"
							className="icon-image"
						/>
					)}
					<div className="icon-content">
						<Trans i18nKey="content_wave">
							<h3>
								WAVE and its faster than real-time component WAVE-RT are at the forefront of technology
								in the realm of 1D CFD analysis of Internal Combustion Engines (ICE).
							</h3>
							<Link to={LINKS.PRODUCTS_WAVE} className="button--more">
								Find out more
							</Link>
						</Trans>
					</div>
				</ContentGrid.Item>

				<ContentGrid.Item
					image={{
						src: data.gridC.childImageSharp.gatsbyImageData,
						alt: '',
					}}>
					{iconVectis && (
						<GatsbyImage
							image={iconVectis}
							alt="VECTIS"
							className="icon-image"
						/>
					)}
					<div className="icon-content">
						<Trans i18nKey="content_vectis">
							<h3>
								VECTIS is a comprehensive CFD software solution empowering
								Internal Combustion Engine (ICE) designers to tackle their most pressing challenges.
							</h3>
							<Link
								to={LINKS.PRODUCTS_VECTIS}
								className="button--more">
								Find out more
							</Link>
						</Trans>
					</div>
				</ContentGrid.Item>
			</ContentGrid>
		</Page>
	);
};

export default ProductFamilyFluidPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-family-fd", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/banner-data.jpg" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/FluidDynamics_htext_400x200.png" }) {
		...imageBreaker
	}
	gridA: file(relativePath: { eq: "content/products/wave/Realis-Simulation_WAVE.png" }) {
		...imageBreaker
	}
	gridC: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS.jpg" }) {
		...imageBreaker
	}
	iconWave: file(relativePath: { eq: "content/products/wave/rs_wave.png" }) {
		...imageBreaker
	}
	iconVectis: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
		...imageBreaker
	}
}
`;
