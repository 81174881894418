import { ImageObject } from '@utils/types';
import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const ContentGrid = ({
	children,
	as: Component = 'section',
	className = '',
}: {
	children: React.ReactNode;
	as?: React.ElementType;
	className?: string;
}) => {
	return (
		<Component
			className={classNames(
				'section__content-grid section--padding background--white',
				{
					[className]: !!className,
				}
			)}>
			<div className="row row--large">{children}</div>
		</Component>
	);
};

const Item = ({
	children,
	image,
}: {
	children: React.ReactNode;
	image: ImageObject;
}) => {
	const gatsbyImage = getImage(image.src);

	return (
		<div className="content-grid-row">
			<div className="content-grid-tile content-grid-tile--text">
				<div className="content-grid-tile-box">{children}</div>
			</div>
			{gatsbyImage && (
				<div className="content-grid-tile content-grid-tile--image">
					<div className="image-background">
						<GatsbyImage
							image={gatsbyImage}
							alt={image.alt}
							objectFit="cover"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

ContentGrid.Item = Item;

export default ContentGrid;
